<template>
	<div>
		<el-dialog :visible.sync="dialogVisible" :append-to-body="isAppend">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
		<!-- :on-preview="handlePictureCardPreview" -->
		<div class="upload-box"
			:style="{width:width,height:height,lineHeight:lHeight,backgroundColor:bgColor,border:bd}">
			<el-upload :action="actionPath" name="file" :headers="header" :before-upload="beforeAvatarUpload"
				:on-success="uploadOnSuccess" :on-exceed="handleExceed" v-loading="upload_loading "
				element-loading-text="上传中" :on-remove="handleRemove" :data="data" class="upload-container">
				<i class="el-icon-plus"></i>
				<div class="show-info" :style="{width:width,height:height,backgroundColor:bgColor}">
					<i v-if="icon" :class="['iconfont',icon]"></i>
					<div v-if="title" class="show-info-text">{{title}}</div>
				</div>
				<div class="show-img" :style="{width:width,height:height}">
					<img v-if="!fUrl" class="default-img" :style="{width:dw,height:dh}" src="../../assets/camera.png" />
					<img v-if="fUrl" class="default-img1" :src="fUrl" :style="{width:width,height:height}" />
				</div>
			</el-upload>
		</div>
		<slot name="tip"></slot>
	</div>
</template>

<script>
	import {
		getToken
	} from "@/utils/auth";

	export default {
		name: "customUpload",
		props: {
			bd: {
				type: String,
				default: '1px dashed #8ADEFB'
			},
			width: {
				type: String,
				default: '80px'
			},
			title: {
				type: String,
				default: ''
			},
			icon: {
				type: String,
				default: ''
			},
			height: {
				type: String,
				default: '80px'
			},
			dw: {
				type: String,
				default: '35px'
			},

			dh: {
				type: String,
				default: '35px'
			},
			lHeight: {
				type: String,
				default: '80px'
			},
			bgColor: {
				type: String,
				default: '#F5F5F5'
			},
			url: {
				type: String,
				value: [],
			},

			tips: {
				type: String,
				default: "",
			},
			isAppend: {
				type: Boolean,
				default: true,
			},
			data: {
				type: Object,
			},
			fileUrl: {
				type: String,
			},
		},

		filters: {
			filterUrl(url) {
				if (!url) {
					return [];
				}
				if (Array.isArray(url)) {
					let pic = url.map((v) => {
						return {
							name: v,
							url: v,
						};
					});
					return pic;
				} else {
					return [{
						name: url,
						url: url,
					}, ];
				}
			},
		},

		data() {
			return {
				header: {
					Authorization: "Bearer " + getToken(),
				},
				fUrl: this.fileUrl,
				actionPath: "/admin/upload",
				dialogImageUrl: "",
				dialogVisible: false,
				upload_loading: false,
			};
		},
		
		methods: {
			//上传图片
			uploadOnSuccess(res, file, fileList) {
				console.log(111111111111)
				this.upload_loading = false
				console.log(1111, res, file);
				if(res.code != 200){
					return this.$message.error(res.message?res.message:res.msg)
				}
				// if (this.limit == 1) {
				if(this.data && this.data.type == 'id_front'){
					this.fUrl = res.data.url;
				}else{
					this.fUrl = res.data;
				}
				
				this.$emit("on-success", res.data);
				// } else {
				//   let urls = fileList.map((v) => {
				//     if (v.response) {
				//       return v.response.data;
				//     } else {
				//       return v.url;
				//     }
				//   });
				//   console.log(1234567, urls);
				//   this.$emit("on-success", urls);
				// }
			},
			//图片预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},

			handleExceed(files, fileList) {
				this.$message.warning(`最多只能上传${this.limit}张图片`);
			},
			//删除图片
			handleRemove(file, fileList) {
				if (this.limit == 1) {
					this.$emit("on-remove", "");
				} else {
					let urls = fileList.map((v) => v.response.key);
					this.$emit("on-remove", urls);
				}
			},

			//图片上传前置见检测
			beforeAvatarUpload(file) {
				// debugger
				console.log(file)
				const isJPG = file.type === "image/jpeg" || file.type === "image/png";
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error("上传图片只能是 JPG 格式!");
					this.upload_loading = false
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2MB!");
					this.upload_loading = false
				}
				let res = isJPG && isLt2M;
				if (res) {
					this.upload_loading = true
				}
				return res;
			},
		},
	};
</script>

<style scoped lang="scss">
	.upload-box {
		position: relative;
		overflow: hidden;
	}

	.show-info {
		position: absolute;
		z-index: 998;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		i {
			font-size: 20px;
			color: #4297E3;
			height: 30px;
		}

		.show-info-text {
			font-size: 14px;
			margin-top: 13px;
		}
	}

	.show-img {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 999;
		top: 0;
		left: 0;

		.default-img {
			opacity: 0.7;
			width: 30px;
			height: 30px;

		}
	}
	
	
	.upload-container{
		line-height: 40px;
	}
</style>
