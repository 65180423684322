<template>
  <div class="gg-container" style="background-color: #f9f9f9">
    <div class="content">
      <div class="nav-left">
        <div
          :class="['nav-left-box', menuIndex == item.value ? 'active' : '']"
          v-for="item in menuOptions"
          v-bind:key="item.value"
        >
          <div class="nav-left-item" @click="selectMenu(item)">
            {{ item.label }}
          </div>
        </div>
      </div>
      <div class="nav-right">
        <platformConfig
          :index="menuIndex"
          :title="title"
          :form="config"
		  :img="config.site_logo"
        ></platformConfig>
      </div>
    </div>
  </div>
</template>

<script>
import platformConfig from "./components/platformConfig.vue";
import { getConfig } from "@/api/basic/index";

export default {
  name: "systemConfig",
  components: {
    platformConfig,
  },

  created() {
    this.getConfig();
    // this.navs = this._getNavList();
    // this._setDictsListProp();
  },
  data() {
    return {
      curIcon: "icon-huanzhechangjing",
      config: {},
      navs: [],
      title: "平台基础",
      menuIndex: 1,
      pageTitle: "网站配置",
      menuOptions: [
        {
          value: 1,
          label: "网站配置",
        },
        {
          value: 2,
          label: "系统配置",
        },
        {
          value: 3,
          label: "小程序",
        },
        {
          value: 4,
          label: "收入费率设置",
        },
        // {
        // 	value: 4,
        // 	label: "短信配置"
        // },
        // {
        // 	value: 5,
        // 	label: "云存储"
        // },
        // {
        // 	value: 6,
        // 	label: "物流跟踪"
        // },
        // {
        // 	value: 7,
        // 	label: "视频配置"
        // },
        // {
        // 	value: 8,
        // 	label: "cerp配置"
        // },
        // {
        // 	value: 9,
        // 	label: "IM配置"
        // },
        // {
        // 	value: 10,
        // 	label: "e签宝"
        // },
        // {
        // 	value: 11,
        // 	label: "APP配置"
        // },
        {
          value: 12,
          label: "平台协议",
        },
        // {
        // 	value: 13,
        // 	label: "APP推送"
        // },
      ],
      isCode: true,
    };
  },
  methods: {
    getConfig() {
      getConfig().then((res) => {
        if (res.code == 200) {
          this.config = res.data;
          //存本地
          localStorage.setItem("img", res.data.site_logo);
        }
      });
    },

    selectMenu(row) {
      this.menuIndex = row.value;
      this.title = row.label;
    },
  },
};
</script>

<style scoped lang="scss">
.dot {
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  margin: 0 5px;
}

.title-icon {
  margin-right: 15px;
  color: #fff;
  font-size: 18px;
  padding: 5px;
  border-radius: 2px;
  background: #2362fb;
}

.content {
  display: flex;

  .nav-left {
    background: #fff;
    width: 220px;
    border-radius: 10px;
    box-shadow: 1px 1px 3px #f9f9f9;
    margin-right: 20px;
    padding-top: 20px;

    .nav-left-box {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 25px;
      box-sizing: border-box;

      i {
        font-size: 12px;
        padding: 5px;
        border-radius: 2px;
        background: rgb(237, 242, 246);
        color: #8a94a6;
      }

      .bg1 {
        color: #fff;
        background: rgb(39, 186, 74);
      }

      .bg2 {
        color: #fff;
        background: rgb(25, 181, 246);
      }

      .bg3 {
        color: #fff;
        background: rgb(112, 74, 253);
      }

      .bg4 {
        color: #fff;
        background: rgb(255, 185, 64);
      }

      .nav-left-item {
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        margin-left: 15px;
      }
    }

    .nav-left-box:hover {
      background: #f6f8fa;
    }

    .active:before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 2px;
      height: 100%;
      background: #2362fb;
    }

    .active {
      background: #f6f8fa;
    }
  }

  .nav-right {
    background: #fff;
    width: 100%;
  }
}
</style>
